import { SlideUpDialog } from '@wavetronix/common-components'
import { useState, useEffect } from 'react'
import { CustomDataGrid, PrimaryButton, SnackbarVariants } from '@wavetronix/common-components'
import CertificationExamsApi from '../../api/CertificationExamsApi'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useSnackbar } from 'notistack'

export default function UserModal(props) {
  const { instance, accounts } = useMsal()
  const [rows, setRows] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const { data, isLoading, refetch } = useQuery(
    'records',
    async () => await CertificationExamsApi.getUserExamRecords(instance, accounts),
    { enabled: false }
  )

  let user = props.user

  const columns = [
    {
      field: 'examName',
      headerName: 'Exam Name',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'issuer',
      headerName: 'Instructor',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'score',
      headerName: 'Score',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'attemptsRemaining',
      headerName: 'Attempts Remaining',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'reset',
      headerName: 'Reset Attempts',
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: p => {
        const onClick = async e => {
          e.stopPropagation()
          let currentRecord = data.filter(record => record.id === p.id)
          let newRecord = { ...currentRecord[0], attemptsRemaining: 3, notified: false }
          await CertificationExamsApi.updateRecord(instance, accounts, newRecord).then(
            _ => {
              enqueueSnackbar('Successfully reset exam attempts', SnackbarVariants.SUCCESS)
            },
            error => {
              console.log(error)
              enqueueSnackbar(`Failed to reset attempts`, SnackbarVariants.ERROR)
            }
          )
          refetch()
        }
        return <PrimaryButton onClick={onClick}>Reset</PrimaryButton>
      }
    }
  ]

  useEffect(() => {
    refetch()
  }, [props.open, refetch])

  useEffect(() => {
    if (data) {
      let best = {}
      let temp = []
      let entries = data.filter(datum => datum.userId === user.id)
      for (let entry of entries) {
        if (entry) {
          best = entry.submissions.filter(submission => {
            if (submission.grade.percentage === Math.max(...entry.submissions.map(sub => sub.grade.percentage))) {
              return true
            }
            return false
          })[0]
          temp.push({
            id: entry.id,
            examName: entry.examName,
            score: best.grade.percentage,
            date: best.date,
            attemptsRemaining: entry.attemptsRemaining,
            issuer: entry.issuerEmail
          })
        }
        setRows(temp)
      }
    }
  }, [data, user.id])

  return (
    <SlideUpDialog
      id='userModal'
      fullScreen
      open={props.open}
      onClose={props.onClose}
      title={
        <h3 style={{ margin: '0px' }}>
          {user.givenName} {user.surname}
        </h3>
      }
    >
      <>
        <div style={{ margin: 10 }}>{user.givenName}'s previous exam attempts</div>
      </>
      <CustomDataGrid autoHeight columns={columns} rows={rows ? rows : []} isLoading={isLoading} />
    </SlideUpDialog>
  )
}
