export const localEnv = {
  runMode: 'dev',
  // runMode: 'prod',
  clientId: 'df84607a-cbc3-4716-bb67-88929ca220a2',
  // clientId: '4558d8b8-170c-4989-b9be-90383c14d479',
  urls: {
    // examsURL: 'http://localhost:5071',
    examsURL: 'https://certificationexams.wtxdev.com',
    userExamURL: 'https://userexam.wtxdev.com',
    gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com'
    // gatekeeperURL: 'http://localhost:5001'
  },
  basicAuthentication: {}
}
